import React from "react"
import SEO from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import HeroBanner from "../components/home/heroBanner/HeroBanner"
import { Container } from "reactstrap"
import TitleBannerEnquiry from "../components/titleBanner/TitleBannerEnquiry"

let PSEA = () => {
    return (
        <>
            <SEO
                title="BIM & CAD Training Center | Ace Industrial Academy"
                description="Ace Industrial Academy is a leading training center in Singapore for IT courses, BIM courses, 3D CAD courses, 4D courses, software courses, building information modeling courses and more."
            />
            <TitleBannerEnquiry
                title="Post-Secondary Education Account"
                description=""
                withOnlyDescription
                hasPlainBanner
            />

            <Container fluid className="pt-6" style={{ backgroundColor: '' }}>
                <Container className="pt-4"><br />
                    <h2 className="trainingTitle">Eligibility for Post-Secondary Education Account (PSEA) Scheme</h2>
                    <p className="trainingContent">
                        <br />
                        1. Singapore Citizen students who have PSEA funds
                        <br />
                        2. Students with Singapore Citizen siblings who have PSEA funds
                        <br /><br />
                    </p>
                    <h5 className="trainingContent">List of courses that accept PSEA Payment: <a href="https://www.aia.edu.sg/courses-skillsfuture/">WSQ Courses</a> </h5>
                    <br /><br />
                </Container>
            </Container>

            <Container fluid className="pt-6" style={{ backgroundColor: '#f1f6ff' }}>
                <Container className="pt-4">
                    <h4 className="trainingContent mt-3">Application Procedure:</h4><br />
                    <p className="trainingContent">
                        1. Student to submit the PSEA Adhoc Application via FormSG: <br /><br />
                        • Using own PSEA only: <a href="https://form.gov.sg/658126310a496f0012d72962"> Training Providers - PSEA Ad Hoc Withdrawal Application | FormSG </a> <br />
                        • Using sibling’s PSEA: <a href="https://form.gov.sg/6596727049e96a001213bfc9"> Training Providers - Authorisation for PSEA Ad Hoc Withdrawal | FormSG </a>
                    </p><br />
                    <p className="trainingContent">
                        2. Trainees should submit the PSEA ad hoc withdrawal applications <strong>no earlier than 2 months before course commencement.</strong><br /><br />
                        3. MOE collates the FormSG applications. Only complete and authorised applications will be collated. Applications that have been pending for
                        more than 2 months will be rejected and excluded from the collation.<br /><br />
                        4. For any unsuccessful withdrawals, ACE INDUSTRIAL ACADEMY follow up with students directly. <br /><br />
                        5. MOE will inform students of successful withdrawals via monthly transaction statement the following month. <br /><br />
                    </p><br /><br />
                </Container>
            </Container>

            <Container fluid className="pt-6" style={{ backgroundColor: '' }}>
                <Container className="pt-4">
                    <h4 className="trainingContent mt-3">Approved Uses of PSEA For Approved Courses</h4><br />
                    <p className="trainingContent">
                        ✓ Course fees and compulsory charges<br /><br />
                        Compulsory charges refer to certification fee and assessment fees:<br /><br />
                        • Certification fees: the fees charged by TP for the certification (of any kind) of the trainee for the purpose of his candidature in the course.<br />
                        • Assessment fees: the fees charged by TP for conducting an assessment (of any kind) of the trainee for the purpose of his candidature in the course.<br /><br />
                        ✓ Goods and Services Tax (GST)<br /><br />
                        PSEA can be used to pay for GST charges, if it is part of the approved fees payable.<br /><br />
                    </p><br /><br />
                </Container>
            </Container>

            <Container fluid className="pt-6" style={{ backgroundColor: '#f1f6ff' }}>
                <Container className="pt-4">
                    <h4 className="trainingContent mt-3">Submitting Claims for PSEA Withdrawals</h4><br />
                    <p className="trainingContent">
                        ✓ Trainees can use their SkillsFuture Credit and/or PSEA for fee deduction.
                        If they apply to use both and the order of fee deduction is not specified by trainees, the default order of deduction:<br /><br />
                        • SkillsFuture Credit (SFC)<br />
                        • Post-Secondary Education Account (PSEA)<br /><br />
                        ✓ ACE INDUSTRIAL ACADEMY shall advise trainees of the final amount, to be withdrawn from their PSEA, after deductions from SkillFuture Credit, if applicable.<br /><br />
                    </p><br /><br />
                </Container>
            </Container>

            <Container fluid className="pt-6" style={{ backgroundColor: '' }}>
                <Container className="pt-4">
                    <h4 className="trainingContent mt-3">Payment of Cash Deposits</h4><br />
                    <p className="trainingContent">
                        ✓ If PSEA withdrawal applications could not be processed in time, payment of cash deposits is required to secure a place in the course. Cash deposit will be refunded upon successful withdrawal from their PSEA.<br /><br />
                        ✓ Cash deposits are returned to the trainees within a reasonable timeline. The mode of such refunds shall be decided by the TP. Where necessary, these should be made known to the trainees.<br /><br />
                    </p><br /><br />
                </Container>
            </Container>

            <Container fluid className="pt-6" style={{ backgroundColor: '#f1f6ff' }}>
                <Container className="pt-4">
                    <p className="trainingContent" style={{ fontSize: '0.9em' }}>
                        <em>Note: Student, parent (or legal guardian) and siblings must be above 21 years old, have SingPass and email accounts to apply/authorise for MOE-PSEA</em>
                    </p>
                    <br />
                    <p className="trainingContent" style={{ fontSize: '0.9em' }}>
                        <em>A trainee and, if using a sibling’s PSEA, the sibling authoriser must be at least 21 years old (exact to date of birth) at the time of applying. If a trainee or the sibling authoriser is below 21 years old, a parent must apply or authorise on behalf, using the parent’s Singpass.</em>
                    </p>
                    <br />
                    <p className="trainingContent" style={{ fontSize: '0.9em' }}>
                        <em>All PSEA withdrawals will be made from the trainee’s own PSEA first. Withdrawals will only be made from their sibling’s PSEA when there are insufficient funds in the trainee’s own PSEA.</em>
                    </p>
                    <br />
                    <p className="trainingContent" style={{ fontSize: '0.9em' }}>
                        <em>You can call the 24-hour automated PSEA hotline at 6260 0777 to check your balance. Latest PSEA balance information will be updated every Wednesday. To find out more about the <a href="https://www.moe.gov.sg/financial-matters/psea">Post-Secondary Education Account (PSEA) | MOE</a></em>
                    </p>
                    <br />
                </Container>
            </Container>
        </>
    )
}
export default PSEA