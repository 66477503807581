import React from "react"
import "./hero.scss"
import SearchBox from "../../common/searchbox/SearchBox"
import FilterButton from "../../common/filter/FilterButton"
import { graphql, navigate, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const HeroBanner = () => {
  const handleClick = () => {
    navigate("/courses?filter")//courses
  }

  const data = useStaticQuery(graphql`
    query {
      sliderimg: file(
        relativePath: { eq: "sliderimg.png" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className="banner">
      <div className="banner_container">
        <div className="bannerimg_content">
          <div className="slidercontent">
            <h1 className="slidertitle">
              <span>Certify</span> your Skills with BIM &
              CAD Courses
            </h1>
            <p className="sliderdescription">
              We conduct trainings with BIM Courses for
              AutoCad, Revit, ProjectWise360, BIM360 and
              many more
            </p>
            <div className="contentform">
              <SearchBox />
              <div className="filter_list">
                <FilterButton handleClick={handleClick} />
              </div>
            </div>
          </div>
          <div className="banner_img">
            <Img
              fluid={data.sliderimg.childImageSharp.fluid}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroBanner
